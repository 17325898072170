import "./App.css";
import profileImage from "../src/profile-circled.png";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <img src={profileImage} alt="" className="profileImage" />
        </div>
        <div>
          <h3>Sorry, Work in progress !</h3>
        </div>
      </header>
    </div>
  );
}

export default App;
